import BlockContent from '@sanity/block-content-to-react';
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import * as React from 'react';

import type { ISanityCopy } from '../../fragments/sanity-copy-parts';
import type { ISanityCopyWithImage } from '../../fragments/sanity-copy-with-image-parts';
import type { ISanityFigure } from '../../fragments/sanity-figure-parts';
import type { TColourScheme } from '../../types';
import { classNames } from '../../utils/classnames';
import {
  BG_COLOUR_MAP,
  PROSE_COLOUR_MAP,
  TEXT_COLOUR_MAP,
} from '../../utils/object-dictionaries';
import { ButtonLink } from '../button-link';
import { ContactInfo } from '../contact-info';

interface CopyWithImageProps {
  copyWithImage: ISanityCopyWithImage;
}

function CopyWithImageBathroom(): JSX.Element {
  return (
    <div className={classNames(BG_COLOUR_MAP['light'])}>
      <div className="w-full px-4 py-12 mx-auto max-w-prose lg:py-24 lg:max-w-screen-2xl sm:px-6 lg:px-8">
        <div className="grid items-center gap-8 lg:grid-cols-2">
          <Figure />
          <Copy />
        </div>
      </div>
    </div>
  );
}

interface FigureProps {
  module: ISanityFigure;
}

function Figure(): JSX.Element {
  return (
    <div className="relative order-last lg:order-none">
      <div
        className="relative overflow-hidden"
        style={{ paddingBottom: '100%' }}
      >
        <div className="absolute inset-0 flex w-full h-full">
          <StaticImage
            src="../../images/bathroom-figure-new.jpg"
            alt=""
            className="flex-1"
          />
        </div>
      </div>
    </div>
  );
}

interface CopyProps {
  module: ISanityCopy;
  colourScheme: TColourScheme;
}

function Copy(): JSX.Element {
  return (
    <div className="pb-5 lg:py-12">
      <div
        className={classNames(
          'prose',
          TEXT_COLOUR_MAP['light'],
          PROSE_COLOUR_MAP['light']
        )}
      >
        <h2>Bathroom</h2>
        Bathrooms are our sanctuary from the pressures of life – and keeping
        your bathroom current is not only good for your peace of mind but also
        increases your home’s value. If your bathroom needs a modern upgrade
        with a fresh new look, Port Macquarie’s Koori Carpentry do the job and
        do it right. Choose from a wide array of styles that suit your style and
        budget, using the highest quality, locally sourced materials.
        <br />
        <br />
        The Koori Carpentry team works with you to renovate or install a
        bathroom that’s 100% yours. Whether it’s a partial or a full bathroom
        renovation, we can tackle the job ensuring a quality result
      </div>

      <p className="mt-5">
        <Link
          to={'/contact-us/'}
          className="inline-block px-6 py-2 text-base font-semibold tracking-wider text-white uppercase bg-primary"
        >
          Contact Us
        </Link>
      </p>
    </div>
  );
}

export { CopyWithImageBathroom };
export type { ISanityCopyWithImage };
